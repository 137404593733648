<script>
export default {
    props: { message: { type: String, required: false}},
    data(){
        return {
            d_message: this.message || this.$t('server-not-responding'),
        };
    }
}
</script>

<template>
    <div v-if="serverNotResponding">
        <slot name="not-responding">
            <b style="color:red" v-if="serverNotResponding">{{ d_message }}</b>
            <div>
                <slot></slot>
            </div>
        </slot>
    </div>
    <div v-else><slot name="responding"></slot></div>
</template>
